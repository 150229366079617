import { createSlice, current } from "@reduxjs/toolkit";
const initialState = {
  data: [],
  loading: false,
  rxUsers: [],
  getMedicines: [],
  searchRxuser: [],
  getSingleRxUsers: [],
  newRxMobileNumber: "",
  searchRxMobileNumber: "",
  medicineId: "",
  rxHistory: [],
  newRx: {},
  diagnostics: {},
  Advice: "",
  idParam: "",
  particularUserPrescription: {},
  savedRX: {},
  searchIdParam: {},
  prescriptionIdParam: {},
  totalCount: null,
  pdfUrl: "",
  doctorList: [],
};

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    getUser(state, action) {
      state.data = action.payload;
    },
    newRxMobileNumber(state, action) {
      state.newRxMobileNumber = action.payload;
    },
    searchRxMobileNumber(state, action) {
      state.searchRxMobileNumber = action.payload;
    },

    getRxUsers(state, action) {
      state.rxUsers = action.payload;
    },
    getSingleRxUsers(state, action) {
      state.getSingleRxUsers = action.payload;
    },
    getSearchRxuser(state, action) {
      state.searchRxuser = action.payload;
    },
    updateMedicinesData(state, action) {
      if (action.payload.shouldReset) {
        state.getMedicines = initialState.getMedicines;
      } else {
        const data = [...state.getMedicines, ...action.payload.medicines];
        const filterByUniqueIds = (data) => {
          const uniqueIds = new Set();
          const uniqueData = [];

          for (const item of data) {
            if (!uniqueIds.has(item.id)) {
              uniqueIds.add(item.id);
              uniqueData.push(item);
            }
          }

          return uniqueData;
        };

        const filteredData = filterByUniqueIds(data);
        state.getMedicines = filteredData;
      }
    },
    emptyDctorList(state, action){
      state.doctorList=[]
    },
    getDoctorList(state, action) {
      const { id, full_name } = action.payload;
      state.doctorList.push({
        id,
        full_name,
      });
    },
    getTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    updateMedicine(state, action) {
      state.getMedicines = [];
    },
    addPrescriptionDetails(state, action) {
      state.newRx[action.payload.id] = {
        diagnosis: action.payload.diagnosis,
        prescriptions: [
          ...(state.newRx[action.payload.id]?.prescriptions || []),
          action.payload.medicalDetail,
        ],
      };
    },
    removePrescriptionKey(state, action) {
      const { id, index } = action.payload;
      if (state.newRx[id] && state.newRx[id].prescriptions[index]) {
        delete state.newRx[id].prescriptions[index].fav;
      }
    },
    clearAddPrescriptionDetails(state, action) {
      state.newRx = {};
    },
    addDiagnosticPrescriptionDetails(state, action) {
      state.diagnostics[action.payload.id] = {
        diagnosis: action.payload.diagnosis,
        diagnosticData: [
          ...(state.diagnostics[action.payload.id]?.diagnosticData || []),
          action.payload.medicalDetail,
        ],
      };
    },
    updateDiagnosticPrescriptionDetails(state, action) {
      const { id, index, medicalDetail } = action.payload;
      if (state.diagnostics[id] && index >= 0 && index < state.diagnostics[id].diagnosticData.length) {
        state.diagnostics[id].diagnosticData[index] = {
          ...medicalDetail,
        };
      }
    },
    filterDiagnosticePrescriptionDetails(state, action) {
      const { id, filterId } = action.payload;
      console.log("callll",filterId)
      if (state.diagnostics[id]) {
        const alldata = state.diagnostics[id]?.diagnosticData;
        console.log(alldata,"DATAALLL")
        const updatedData = alldata.map((item) => {
          // Check if item id matches filterId
          if (item?.id === filterId) {
            return {
              "brand_name_short": item.brand_name_short,
              "additional_instruction": item.additional_instruction,
              "is_diagnostic": item.is_diagnostic,
            };
          }

          // Return unchanged item for others
          return item;
        });
        console.log(updatedData,"updatedData")
        // Update the state with the new diagnosticData
        state.diagnostics[id].diagnosticData = updatedData;
      }else{
        console.log("idnotmatchhh")
      }
    },
    
 

    editDiagnosticPrescriptionDetails(state, action) {
      const { id, formData, index } = action.payload;
      const updatedDiagnostics = state.diagnostics[id].diagnosticData.map(
        (item, idx) => (idx === index ? formData : item)
      );
      state.diagnostics[id].diagnosticData = updatedDiagnostics;
    },
    deleteDiagnosticPrescriptionsDetails(state, action) {
      const { id, medicineId } = action.payload;
      if (state?.diagnostics?.[id]) {
        state.diagnostics[id].diagnosticData = state.diagnostics[
          id
        ].diagnosticData.filter((item, index) => index !== medicineId);
      }
    },
    clearDiagnosticsPrescriptionsDetails(state, action) {
      state.diagnostics = {};
      state.Advice = "";
    },

    clearNewRxDetail(state, action) {
      state.newRx = {};
      state.Advice = "";
    },

    addDiagnosis(state, action) {
      state.savedRX[action?.payload?.existingMemberId] = action?.payload?.value;
    },
    clearAddDiagnosis(state, action) {
      state.savedRX = {};
    },

    deletePrescriptionsDetails(state, action) {
      const { id, medicineId } = action.payload;
      if (state?.newRx?.[id]) {
        state.newRx[id].prescriptions = state.newRx[id].prescriptions.filter(
          (item, index) => index !== medicineId
        );
      }
    },
    editPrescriptionDetails(state, action) {
      const { id, formData, index } = action.payload;
      const updatedPrescriptions = state.newRx[id].prescriptions.map(
        (item, idx) => (idx === index ? formData : item)
      );
      state.newRx[id].prescriptions = updatedPrescriptions;
    },
    rxHistory(state, action) {
      state.rxHistory = action?.payload;
    },
    medicineId(state, action) {
      state.medicineId = action?.payload;
    },
    getIdParam(state, action) {
      state.idParam = action?.payload;
    },
    getsearchIdParam(state, action) {
      state.searchIdParam = action?.payload;
    },
    getprescriptionIdParam(state, action) {
      state.prescriptionIdParam = action?.payload;
    },
    clearIdParam(state, action) {
      state.idParam = "";
    },
    clearState(state, action) {
      state[action.payload] = [];
    },

    particularUserPrescription(state, action) {
      state.particularUserPrescription = action?.payload;
    },
    getPdfUrl(state, action) {
      state.pdfUrl = action?.payload;
    },
    clearPdf(state, action) {
      state.pdfUrl = "";
    },
  },
});

export const {
  setLoading,
  getUser,
  getRxUsers,
  getMedicines,
  updateMedicinesData,
  updateMedicine,
  clearState,
  newRxMobileNumber,
  searchRxMobileNumber,
  getSearchRxuser,
  getSingleRxUsers,
  addPrescriptionDetails,
  clearAddPrescriptionDetails,
  removePrescriptionKey,
  addDiagnosticPrescriptionDetails,
  updateDiagnosticPrescriptionDetails,
  filterDiagnosticePrescriptionDetails,
  medicineId,
  deletePrescriptionsDetails,
  rxHistory,
  clearNewRxDetail,
  addDiagnosis,
  editPrescriptionDetails,
  getIdParam,
  clearIdParam,
  particularUserPrescription,
  getsearchIdParam,
  getprescriptionIdParam,
  getTotalCount,
  clearAddDiagnosis,
  editDiagnosticPrescriptionDetails,
  clearDiagnosticsPrescriptionsDetails,
  deleteDiagnosticPrescriptionsDetails,
  getPdfUrl,
  clearPdf,
  getDoctorList,
  emptyDctorList
} = user.actions;
export default user.reducer;
