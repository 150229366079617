import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  assistants: [],
  waitingLists: [],
};
export const assistant = createSlice({
  name: "assistant",
  initialState,
  reducers: {
    addAssistant(state, action) {
      const { name, phoneNumber, id ,assistant_id} = action.payload;
      state.assistants.push({
        name,
        phoneNumber,
        id,
        assistant_id
      });
    },
    showWaitingList(state, action) {
      const { name, age, mobile_number, id,sex,clinical_detail, createdAt} = action.payload;
      state.waitingLists.push({
        name,
        age,
        mobile_number,
        id,
        sex,
        clinical_detail,
        createdAt

      });
    },
    removeUser(state, action) {
      const { id } = action.payload;
      state.waitingLists = state.waitingLists.filter((item) => item.id !== id);
    },
    emptyWaitingList(state,action){
      state.waitingLists=[]
    },
    updateUser(state, action) {
      const { age, name, mobile_number, id,sex } = action.payload;
      const index = state.waitingLists.findIndex((user) => user.id === id);
      if (index !== -1) {
        state.waitingLists[index].name = name;
        state.waitingLists[index].mobile_number = mobile_number;
        state.waitingLists[index].age = age;
        state.waitingLists[index].sex = sex;
      }
    },
    removeAssistant(state, action) {
      const { id } = action.payload;
      state.assistants = state.assistants.filter((item) => item.id !== id);
    },
    emptyAssistantList(state, action){
state.assistants=[]
    },
    updateAssistant: (state, action) => {
      const { id, name, phoneNumber } = action.payload;
      const index = state.assistants.findIndex(
        (assistant) => assistant.id === id
      );
      if (index !== -1) {
        state.assistants[index].name = name;
        state.assistants[index].phoneNumber = phoneNumber;
      }
    },
  },
});

export const {
  addAssistant,
  removeAssistant,
  updateAssistant,
  showWaitingList,
  removeUser,
  updateUser,
  emptyAssistantList,
  emptyWaitingList
} = assistant.actions;
export default assistant.reducer;
