import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  GET_USER: false,
  PAGE_LOADING: false,
  SEARCH_RX: false,
  Rx_USER: false,
  Approve_User: false,
  confirm_OTP: false,
  Edit_Profile: false,
  ADD_PRESCRIPTION: false,
  SEARCH_MEDICINES: false,
  GET_RX_HISTORY: false,
  PRINT_PDF: false,
  CLINIC_DETAIL: false,
  DELETE_USER: false,
};
export const loading = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading(state, action) {
      state[action.payload[0]] = action.payload[1];
    },
  },
});

export const { setLoading } = loading.actions;
export default loading.reducer;
