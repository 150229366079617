const LogoutIcon = () => {
  return (
    <>
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.34883 0.0549997C7.86112 -0.285407 8.95808 1.01433 8.95808 2.29941V13.7006C8.95808 14.9857 7.86112 16.2854 6.34883 15.945C2.71511 15.1274 0 11.8813 0 8C0 4.11871 2.71511 0.872628 6.34883 0.0549997ZM10.8254 4.98113C10.9781 4.82846 11.1852 4.74269 11.4012 4.74269C11.6171 4.74269 11.8242 4.82846 11.9769 4.98113L14.4201 7.42424C14.5727 7.57696 14.6585 7.78406 14.6585 8C14.6585 8.21594 14.5727 8.42304 14.4201 8.57576L11.9769 11.0189C11.8234 11.1672 11.6176 11.2493 11.4041 11.2474C11.1906 11.2456 10.9863 11.1599 10.8353 11.009C10.6844 10.858 10.5987 10.6537 10.5969 10.4402C10.595 10.2267 10.6771 10.0209 10.8254 9.86735L11.8784 8.81437H4.88622C4.67024 8.81437 4.4631 8.72857 4.31038 8.57585C4.15765 8.42312 4.07185 8.21598 4.07185 8C4.07185 7.78402 4.15765 7.57688 4.31038 7.42415C4.4631 7.27143 4.67024 7.18563 4.88622 7.18563H11.8784L10.8254 6.13265C10.6728 5.97993 10.587 5.77283 10.587 5.55689C10.587 5.34095 10.6728 5.13384 10.8254 4.98113Z"
          fill="#105CAA"
        />
      </svg>
    </>
  );
};
export default LogoutIcon;
