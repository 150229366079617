export const validateMobileNumber = (number) => {
  // Regular expression to validate the mobile number (Assuming +91 followed by 10 digits)
  const mobileNumberRegex = /^[6-9]\d{9}$/;
  return mobileNumberRegex.test(number);
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const isAuthenticated = () => {
  const user = localStorage.getItem("token");
  return !!user; // Returns true if user exists in localStorage, otherwise false
};
export function convertTimestampToReadableDate(isoTimestamp) {
  // Create a Date object from the ISO timestamp

  let date = new Date(isoTimestamp);

  // Define an array of month names
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day, month, and year components from the Date object
  let day = date.getDate();
  let month = monthNames[date.getMonth()];
  let year = date.getFullYear();
  // Create the formatted date string
  let formattedDate = day + " " + month + " " + year;

  return formattedDate;
}
export const getCurrentTime = () => {
  const currentDate = new Date();
  const options = { day: "numeric", month: "long", year: "numeric" };
  return currentDate.toLocaleDateString(undefined, options);
};

export function convertToAMPM(time24) {
  let hour = parseInt(time24.substring(0, 2));
  let min = time24.substring(3, 5);
  let period = hour >= 12 ? "PM" : "AM";

  // Convert hour from 24-hour to 12-hour format
  hour = hour % 12;
  hour = hour ? hour : 12; // "0" should be "12"

  return `${hour}:${min} ${period}`;
}