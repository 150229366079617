import React from "react";

export default function WhatsApp() {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.99 6.547C21.9817 6.11797 21.9473 5.68985 21.887 5.265C21.8152 4.8871 21.6931 4.52052 21.524 4.175C21.1539 3.4443 20.5605 2.85057 19.83 2.48C19.4867 2.31171 19.1225 2.18997 18.747 2.118C18.3189 2.05648 17.8874 2.02142 17.455 2.013C17.272 2.006 17.035 2.003 16.925 2.003L7.077 2C6.967 2 6.73 2.003 6.547 2.01C6.11797 2.01827 5.68984 2.05266 5.265 2.113C4.8871 2.18484 4.52052 2.30692 4.175 2.476C3.44434 2.8462 2.85063 3.43956 2.48 4.17C2.31176 4.51332 2.19002 4.87752 2.118 5.253C2.0562 5.68109 2.0208 6.11257 2.012 6.545C2.006 6.728 2.002 6.965 2.002 7.075L2 16.923C2 17.033 2.003 17.27 2.01 17.453C2.01827 17.882 2.05266 18.3102 2.113 18.735C2.18485 19.1129 2.30693 19.4795 2.476 19.825C2.8462 20.5557 3.43956 21.1494 4.17 21.52C4.51332 21.6882 4.87753 21.81 5.253 21.882C5.6811 21.9435 6.11258 21.9786 6.545 21.987C6.728 21.994 6.965 21.997 7.075 21.997L16.923 21.999C17.033 21.999 17.27 21.996 17.453 21.989C17.882 21.9807 18.3102 21.9463 18.735 21.886C19.1129 21.8141 19.4795 21.692 19.825 21.523C20.556 21.153 21.1501 20.5596 21.521 19.829C21.6893 19.4857 21.811 19.1215 21.883 18.746C21.9448 18.3179 21.9802 17.8864 21.989 17.454C21.995 17.271 21.999 17.034 21.999 16.924L22 7.077C22 6.967 21.997 6.73 21.99 6.547ZM12.217 18.957H12.214C11.0237 18.9568 9.85235 18.6583 8.807 18.089L5.027 19.08L6.039 15.387C5.25545 14.0273 4.94237 12.4471 5.1483 10.8914C5.35423 9.33566 6.06765 7.89133 7.17797 6.78233C8.28829 5.67333 9.73347 4.96162 11.2894 4.75755C12.8454 4.55347 14.4252 4.86843 15.784 5.65359C17.1427 6.43876 18.2045 7.65026 18.8046 9.10026C19.4048 10.5503 19.5098 12.1578 19.1034 13.6735C18.697 15.1893 17.8018 16.5286 16.5568 17.4839C15.3117 18.4391 13.7863 18.9569 12.217 18.957Z"
          fill="url(#paint0_linear_2_188)"
        />
        <path
          d="M12.22 5.90099C11.1611 5.90068 10.1214 6.18407 9.20912 6.72168C8.29683 7.25929 7.54523 8.03149 7.03247 8.95798C6.51972 9.88447 6.26454 10.9314 6.29347 11.9899C6.32241 13.0485 6.63439 14.0799 7.19699 14.977L7.33799 15.201L6.73899 17.387L8.98199 16.799L9.19799 16.927C10.1111 17.4678 11.1528 17.7531 12.214 17.753H12.217C13.7888 17.7533 15.2963 17.1291 16.408 16.0179C17.5196 14.9066 18.1442 13.3993 18.1445 11.8275C18.1448 10.2557 17.5206 8.74815 16.4094 7.63653C15.2981 6.52491 13.7908 5.90026 12.219 5.89999L12.22 5.90099ZM15.704 14.371C15.5729 14.5875 15.3985 14.7745 15.1916 14.9202C14.9847 15.066 14.7499 15.1674 14.502 15.218C14.1276 15.2822 13.7435 15.2582 13.38 15.148C13.0353 15.0406 12.6965 14.9151 12.365 14.772C11.1347 14.1546 10.0861 13.2279 9.32199 12.083C8.90517 11.553 8.65215 10.9128 8.59399 10.241C8.58919 9.96371 8.64219 9.68846 8.74963 9.43279C8.85706 9.17712 9.01657 8.94662 9.21799 8.75599C9.27752 8.68809 9.35044 8.63322 9.43219 8.59485C9.51393 8.55647 9.60272 8.53541 9.69299 8.53299C9.81099 8.53299 9.92999 8.53299 10.034 8.53899C10.144 8.54399 10.29 8.49699 10.434 8.84499C10.584 9.20099 10.94 10.078 10.984 10.167C11.0122 10.2137 11.0284 10.2666 11.031 10.3211C11.0336 10.3756 11.0226 10.4298 10.999 10.479C10.9541 10.586 10.8942 10.686 10.821 10.776C10.731 10.88 10.634 11.008 10.554 11.088C10.464 11.177 10.372 11.273 10.475 11.451C10.7425 11.909 11.0765 12.3249 11.466 12.685C11.8886 13.0607 12.3736 13.3599 12.899 13.569C13.077 13.659 13.181 13.643 13.285 13.524C13.389 13.405 13.73 13.004 13.849 12.826C13.968 12.648 14.086 12.678 14.249 12.737C14.412 12.796 15.289 13.227 15.467 13.317C15.645 13.407 15.764 13.45 15.808 13.524C15.8568 13.8104 15.8206 14.1049 15.704 14.371Z"
          fill="url(#paint1_linear_2_188)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2_188"
            x1="12"
            y1="2"
            x2="12"
            y2="21.999"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54DB25" stop-opacity="0.81" />
            <stop offset="1" stop-color="#71BF24" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2_188"
            x1="12.2179"
            y1="5.89999"
            x2="12.2179"
            y2="17.753"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54DB25" stop-opacity="0.81" />
            <stop offset="1" stop-color="#71BF24" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
