import React from "react";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";
import BtnBackIcon from "assets/svg/BtnBackIcon";

export default function UtilityButton({
  btnName,
  bgColor,
  width,
  height = "50px",
  color,
  border = "none",
  borderRadios = "100px",
  onclick,
  loading,
  suffix,
  padding = "18px",
  whiteSpace = "nowrap",
  fontSize,
  disabled,
  ...props
}) {
  return (
    <Button
      style={{
        backgroundColor: disabled
          ? "gray"
          : bgColor == null
          ? "#105CAA"
          : bgColor,
        fontWeight: 700,
        fontSize: { fontSize },
        borderRadius: borderRadios,
        width: width,
        border: border,
        height: height,
        color: color,
        padding: padding,
        whiteSpace: whiteSpace,
      }}
      disabled={disabled}
      variant="primary"
      type="submit"
      className="d-flex justify-content-center align-items-center cursor-pointer"
      onClick={onclick}
      {...props}
    >
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          {btnName} {suffix && <span className="ml-6">{suffix}</span>}
        </div>
      )}
    </Button>
  );
}

UtilityButton.propTypes = {
  btnName: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  border: PropTypes.string,
  borderRadios: PropTypes.string,
  onclick: PropTypes.func,
};
