import React from "react";
import LogoIcon from "assets/svg/LogoIcon";
import "./footer.css";
import WhatsApp from "assets/svg/WhatsApp";
import { router } from "App";
export default function WebFooter() {
  return (
    <>
      <div
        className="d-flex"
        style={{ background: "#d3edfc", paddingBottom: "50px" }}
      >
        <div style={{ width: "90%", margin: "auto" }}>
          <div className="d-flex flex-column flex-wrap">
            {/* <div className="d-flex align-items-center mt-50">
              <LogoIcon width="61px" height="57px" />

             
            </div> */}
            <div className="footer-li d-flex justify-content-between mt-24 ">
              <span className="quotes fw-600 fs-18">
                A digital prescription pad for doctors.
                <p></p>
                <p>
                  A faster, safer & convenient way to generate National Medical
                  Commission compliant digital Rx.
                </p>
              </span>

              <span className="text-decoration-none">
                <h2
                  className="fs-20 cursor-pointer"
                  onClick={() => router.navigate("/about-us")}
                >
                  About Us
                </h2>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
