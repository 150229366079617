import { AXIOS } from "utils/axios";
import { store } from "redux-store/store";
import { toast } from "react-toastify";
import { removeUser } from "redux-store/slices/assistant";
import { setLoading } from "redux-store/slices/loading";
import { router } from "App";
class WaitingListService {
  config = {
    headers: {
      Authorization: `${localStorage.getItem("token")}`,
    },
  };
  url = "/waiting-patients";
  async getWaitingList() {
    const response = await AXIOS.get(this.url, {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  }
  async addUserToWaitingList(data) {
    const postObj = {
      patient_name: data?.patientName,
      patient_mobile_number: data?.phoneNumber,
      age: data?.age,
      sex: data?.gender,
      doctor_id: data?.doctorId,
      clinical_detail: data?.clinicalDetail,
    };

    const response = await AXIOS.post(this.url, postObj, {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
    if (response?.data?.status) {
      toast.success(response?.data?.message);
      store.dispatch(setLoading(["CLINIC_DETAIL", false]));
      router.navigate("/home");
    }
  }

  async remoeUserFromWaitingList(patientId) {
    const response = await AXIOS.delete(this.url, {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: {
        patientId: patientId,
      },
    });
    if (response?.data?.status) {
      store.dispatch(removeUser({ id: patientId }));
    } else {
      toast.error(response?.data);
    }
    return response;
  }
}

export default new WaitingListService();
