import { configureStore } from "@reduxjs/toolkit";
import onBoardingReducer from "./slices/onBoarding";
import userReducer from "./slices/user";
import loadingReducer from "./slices/loading";
import assistant from "./slices/assistant"

export const store = configureStore({
  reducer: {
    onBoarding: onBoardingReducer,
    user: userReducer,
    loading: loadingReducer,
    assistant:assistant
  },
});
