const LogoIcon = ({ width, heigth }) => {
  return (
    <>
      <svg
        width="57"
        height="54"
        viewBox="0 0 57 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="12.415"
          y="41.4473"
          width="47.8584"
          height="23.1419"
          rx="11.5709"
          transform="rotate(-60 12.415 41.4473)"
          fill="#0152A8"
        />
        <rect
          x="23.9297"
          y="53.1191"
          width="47.8584"
          height="23.1419"
          rx="11.5709"
          transform="rotate(-120 23.9297 53.1191)"
          fill="#0FE3AF"
        />
        <path
          d="M18.1369 31.5031C14.9305 37.0443 16.839 44.1332 22.3997 47.3365C27.9603 50.5397 35.0674 48.6444 38.2738 43.1031L38.4005 42.8841C40.2392 39.4659 40.3171 35.2079 38.2319 31.6045L28.1447 14.1738L18.1369 31.5031Z"
          fill="#105CAA"
        />
      </svg>
    </>
  );
};
export default LogoIcon;
